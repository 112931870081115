import * as R from 'ramda'
import React from 'react'

import * as ServerConfig from './server-configuration'
import * as Http from './http-module'
import * as Utils from './utils'

export function createLocalizedComponent(name, components) {
  const Switchable = Utils.Components.createSwitchableComponent(
    'region',
    components
  )

  function LocalizedComponent(props) {
    const { country } = ServerConfig.useContext()
    const locale = Http.useLocale()

    const fallback = country.enabled ? '__default__' : '__unsupported__'
    const region =
      country.enabled && R.has(locale.region, components)
        ? locale.region
        : fallback

    return <Switchable {...props} region={region} />
  }

  LocalizedComponent.displayName = `Localized(${name})`

  return LocalizedComponent
}
