import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./login-page-playboom')),
  playboom24: loadable(() => import('./login-page-playboom')),
  wettenlive: loadable(() => import('./login-page-wettenlive')),
  casinosky: loadable(() => import('./login-page-casinosky')),
  casinoskyjp: loadable(() => import('./login-page-casinosky')),
  paradiseplay: loadable(() => import('./login-page-casinosky')),
}

export const LoginPage = createBrandedComponent('LoginPage', BRANDED_COMPONENTS)

export default LoginPage
