import * as React from 'react'
import PropTypes from 'prop-types'

export class UniversalFetch extends React.PureComponent {
  constructor(props) {
    super(props)
    props.onFetch()
  }

  render() {
    return this.props.children
  }
}

UniversalFetch.defaultProps = {
  children: null,
  onFetch: () => {},
}

UniversalFetch.propTypes = {
  children: PropTypes.node,
  onFetch: PropTypes.func.isRequired,
}
