import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import { LoginPage } from '../login-page'
import { UniversalFetch } from '../universal-fetch'
import { createLocalizedComponent } from '../create-localized-component'

const LOCALIZED_COMPONENTS = {
  __default__: loadable(() => import('./landing-page-default')),
  __unsupported__: loadable(() => import('./landing-page-unsupported')),
  DE: loadable(() => import('./landing-page-default')),
  SE: loadable(() => import('./landing-page-default')),
  FR: loadable(() => import('./landing-page-default')),
}

const LandingPage = createLocalizedComponent(
  'LandingPage',
  LOCALIZED_COMPONENTS
)

export function LandingPageContainer(props) {
  const [returningPlayer] = Cookies.useCookie(
    Constants.CookieKeys.RETURNING_PLAYER
  )

  if (returningPlayer) {
    return <LoginPage />
  }

  return (
    <UniversalFetch onFetch={props.onFetch}>
      <Common.Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <LandingPage {...props} returningPlayer={returningPlayer} />
      </Common.Box>
    </UniversalFetch>
  )
}

LandingPageContainer.propTypes = {
  onFetch: PropTypes.func.isRequired,
}
