import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Offers from '@rushplay/offers'

import * as player from '../player'

import { LandingPageContainer as Component } from './landing-page-container'

const LandingPage = R.compose(
  connect(state => {
    const offers = Offers.getCalculatedOffers(state.offers, {
      depositNumber: 0,
      amountCents: 0,
    })
    const bonusMoneyOffer = R.find(R.path(['bonusMoneyPercent']), offers)
    const bonusRoundsOffer = R.find(R.path(['maxFixedFeatureTriggers']), offers)
    const freespinsOffer = R.find(R.path(['maxFixedFreespins']), offers)

    return {
      affiliateId: player.getAffiliateId(state.player),
      bonusPercentage: R.pathOr(0, ['bonusMoneyPercent'], bonusMoneyOffer),
      maxBonusAmount: R.pathOr(
        0,
        ['bonusMoneyPercentMaxCents'],
        bonusMoneyOffer
      ),
      maxFeatureTriggers: R.pathOr(
        0,
        ['maxFixedFeatureTriggers'],
        bonusRoundsOffer
      ),
      maxFixedFreespins: R.pathOr(0, ['maxFixedFreespins'], freespinsOffer),
    }
  }),
  connect(null, (dispatch, props) => ({
    onFetch: () =>
      dispatch(Offers.fetchDepositOffers({ affiliateId: props.affiliateId })),
  }))
)(Component)

// for @loadable/components
export default LandingPage
